.container-lg {
  max-width: 800px;
}

.left {
  button {
    text-align: left;
  }
}

.right {
  img {
    max-height: 600px;
  }
}